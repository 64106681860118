<template>
  <div class="content">
		<img class="logo" src="../assets/image/321.png" alt="">
		<div class="login_content" v-if="!isMobileBg">
			<h2>尙云方统筹开方平台</h2>
			<div class="content_login">
				<h2>登陆</h2>
				<div class="content_input-right-input">
					<el-input  prefix-icon="el-icon-user" v-model="UserName" @keyup.enter.native="submit" clearable placeholder="用户名"></el-input>
                	<el-input prefix-icon=" el-icon-lock" style="margin-top:8%" @keyup.enter.native="submit" v-model="PassWord" clearable show-password placeholder="密码"></el-input>
					<el-button style="margin-top:8%; width:100%;background: #f8d347; color: #000;height: 60px;"  @click="SignIn">登录</el-button>
					<div class="content_input_logon">
						<el-checkbox v-model="checked" style="color: #000;">下次自动登录</el-checkbox>
						<p >
							<!-- <span style="cursor: pointer">忘记密码</span>
							<span>&nbsp;&nbsp;|&nbsp;&nbsp;</span> -->
							<span @click="project" style="cursor: pointer">连锁店注册</span>
							<span>&nbsp;&nbsp;|&nbsp;&nbsp;</span>
							<span @click="register" style="cursor: pointer">单体店注册</span>
						</p>
					</div>
				</div>
			</div>
			<!-- <div class="footer_">药品医疗器械网络信息服务备案（京）网药械信息备字（2023）第00362号</div> -->
		</div>
		<div class="login_content" style="width: 85%;" v-if="isMobileBg">
			<h2>尙云方统筹开方平台</h2>
			<div class="content_login" >
				<h2>登陆</h2>
				<div class="content_input-right-input">
					<el-input  prefix-icon="el-icon-user" v-model="UserName" @keyup.enter.native="submit" clearable placeholder="用户名"></el-input>
                	<el-input prefix-icon=" el-icon-lock" style="margin-top:8%" @keyup.enter.native="submit" v-model="PassWord" clearable show-password placeholder="密码"></el-input>
					<el-button style="margin-top:8%; width:100%;background: #f8d347; color: #000;height: 60px;"  @click="SignIn">登录</el-button>
					<div class="content_input_logon">
						<el-checkbox v-model="checked" style="color: #000;">下次自动登录</el-checkbox>
						<p >
							<!-- <span style="cursor: pointer">忘记密码</span>
							<span>&nbsp;&nbsp;|&nbsp;&nbsp;</span> -->
							<!-- <span @click="project" style="cursor: pointer">连锁店注册</span>
							<span>&nbsp;&nbsp;|&nbsp;&nbsp;</span>
							<span @click="register" style="cursor: pointer">单体店注册</span> -->
						</p>
					</div>
				</div>
			</div>
			<!-- <div class="footer_">药品医疗器械网络信息服务备案（京）网药械信息备字（2023）第00362号</div> -->
		</div>
    </div>
</template>
<script>
import module_function from '../utils/login'
export default {
	data() {
	return {
		UserName:'',
		PassWord:'',
		checked:false,
		isMobileBg:false
	}
	},
	methods:{
		project(){
			const h = this.$createElement;
			this.$msgbox({
				title: '提醒',
				message: h('p', { style: 'text-align: center' }, [
					h('i', { style: 'font-weight: 400' }, '请联系'),
					h('i', { style: 'font-weight: 800' }, ' 134 0358 7620 或 153 8683 1192'),
			
				]),
				confirmButtonText: '确定',
				})
		},
		submit(e){
			if (e.keyCode == 13) this.SignIn();
		},
		SignIn(){
			let url = window.location.href;
			if(url.indexOf('?') != -1){
				let obj = {};
				let arr = url.slice(url.indexOf('?')+1).split('&');
				arr.forEach(item => {
					let param = item.split('=');
					obj[param[0]] = param[1];
				})
				console.log(obj);
				module_function.login(this.UserName,this.PassWord,'/CallStation',obj.role)
			}else{
				let url = this.isMobileBg ? '/Mobile' : '/overview'
				module_function.login(this.UserName,this.PassWord,url,)
				// Mobile
			}

		},
		register(){
			this.$router.push('/register')
		},
		isMobile() {
			let flag = navigator.userAgent.match(/(phone|pad|pod|iPhone|iPod|ios|iPad|Android|Mobile|BlackBerry|IEMobile|MQQBrowser|JUC|Fennec|wOSBrowser|BrowserNG|WebOS|Symbian|Windows Phone)/i)
			return flag;
		},
	
	},
	created(){
		if(this.isMobile()){
			this.isMobileBg = true
		}
		sessionStorage.removeItem('guide');
		console.log()
	},
}
</script>
<style lang="less" scoped>
.logo{
	width: 62px;
	height: 62px;
	position: absolute;
	left: 2%;
	top: 2%;
}
.content{
	width: 100%;
	// min-width: 1600px;
	// min-height: 789px;
	height: 100%;
	background-image: url('../assets/image/logonbg.png');
	background-size: 100% 100%;
	background-repeat: no-repeat;
}
.login_content{
    width: 25%;
    position: absolute;
	top: 0;
	bottom: 0;
	left: 0;
	right: 0;
	margin: auto;
}
.login_content>h2{
	width: 200%;
	position: relative;
	left: -50%;
	color: #fff;
	text-align: center;
	padding: 20px;
	font-weight: 600;
	font-size: 1.8em;
	margin-top: 40%;
	letter-spacing:8px;
}
.footer_{
	width: 200%;
	position: fixed;
	color: #fff;
	// text-align: center;
	// letter-spacing:2px;
	// margin-top: 20%;
	bottom: 20px;
}
.content_login{
	background: #fff;
	border-radius: 6px;
	padding: 20px;
}
.content_login>h2{
	padding: 20px;
	padding-left: 0;
	color: #000;
}
.content_input_logon{
	display: flex;
	justify-content: space-between;
	font-size: 14px;
	margin-top: 8%;
	padding-bottom: 10%;
}
// .content_input_img{
// 	width: 40%;
// }
// .content_input_img img{
// 	width: 100%;
// }
// .content_input .absolute_{
// 	position: absolute;
//     left: 4%;
//     bottom: 5%;
// }
// .content_input-right{
// 	position: absolute;
// 	left: 50%;
// 	width: 50%;
// 	height: 100%;
// 	padding: 6%;
// }
// .content_input-right-input{
// 	width: 70%;
// 	margin: 0 auto;
// 	margin-top:10%;
// }
// .content_input-right-title{
// 	display: flex;
// 	justify-content: center;
// 	align-items: center;
// 	.content_input-right-title-img{
// 		width: 100%;
// 		img{
// 			width: 100%;
// 		}
// 	}
// 	.content_input-right-title-text{
// 		// text-align: center;
// 		font-size: 40px;
// 		font-weight: 600;
// 		margin-left: 2%;
// 		font-family:"Hiragino Sans GB";
// 		height: 119px;
// 		.content_input-right-title-text2{
// 			margin-top: 8px;
// 			font-size: 23px;
// 			font-weight: 500;
// 			color: rgb(150, 150, 150);
// 			letter-spacing:8px;
// 		}
// 	}
	
// }
</style>